@import (reference) 'src/styles/variables.less';
@import (reference) 'src/styles/mixins.less';

.plan-item {
  width: 308px;
  min-height: 353px;
  border-radius: 19px;
  position: relative;
  .clickable();
  border: 2px solid transparent;
  overflow: hidden;

  .item-box {
    border-radius: 19px;
    overflow: hidden;

    .active-box {
      width: 33px;
      height: 35px;
      border-radius: 0px 0px 0px 35px;
      position: absolute;
      right: 0;
      top: 0;
      .flex-row();
      .ame-icon{
        font-size: 18px;
      }
    }

  }

  &.pro {
    .item-box {
      border: 1px solid #E3DAFF;
      background: linear-gradient(316deg, #F6F5FE 0%, #F0EFFE 30%, #F2EFFE 46%, #ECE8FE 62%, #E9E5FE 100%);
      .active-box{
        background: #DDD2FF;
        .ame-icon{
          color: #7843F8;
        }
      }
    }

    .version-info {
      background: linear-gradient( 119deg, #FFFFFF 0%, #EFEBFE 46%, #FFFFFF 100%);

      span {
        font-size: 19px;
        color: #906BDA;
        line-height: 26px;
        text-align: left;
        font-style: normal;
        background: linear-gradient(58.54034973461311deg, #8F61ED 0%, #8453F2 45%, #692FFF 100%, #6040ED 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    &:hover, &.active {
      box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.1);
      border: none;

      .item-box {
        background: linear-gradient(316deg, #F6F5FE 0%, #F0EFFE 30%, #F2EFFE 46%, #ECE8FE 62%, #E9E5FE 100%);
        border: 3px solid#DDD2FF;
      }
    }

  }

  &.pro-v {
    .item-box {
      background: linear-gradient(316deg, #FEFAF5 0%, #FEF9EF 31%, #FEF9EF 46%, #FEF8E8 62%, #FEF2E5 100%);
      border: 1px solid #FFE3AE;
      .active-box{
        background: #FFE3AE;
        .ame-icon{
          color: #AB6C00;
        }
      }
    }


    &:hover, &.active {
      box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.1);
      border: none;

      .item-box {
        background: linear-gradient(316deg, #FEFAF5 0%, #FEF9EF 31%, #FEF9EF 46%, #FEF8E8 62%, #FEF2E5 100%);
        border: 3px solid #FFE3AE;
      }
    }

    .version-info {
      span {
        font-size: 19px;
        color: #986BDA;
        line-height: 26px;
        text-align: left;
        font-style: normal;
        background: linear-gradient(50.95069484660149deg, #DBA10A 0%, #DEA541 45%, #CA820A 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }

  .version-info {
    min-width: 124px;
    width: fit-content;
    height: 36px;
    border-radius: 0px 0px 16px 0px;
    background: linear-gradient(316deg, #FCFAF6 0%, #FEF9EF 31%, #FFFAF2 46%, #FFFBF0 62%, #FFFAF5 100%);
    border: 1px solid #FFFFFF;
    .flex-row();
    font-weight: 600;

    .icon {
      width: 26px;
      margin-right: 8px;
    }
  }

  .b-list {
    padding: 30px 26px;

    .b-item {
      display: flex;
      align-items: center;

      &:not(:first-child) {
        margin-top: 35px;
      }

      .ame-icon {
        font-size: 22px;
        margin-right: 14px;
      }

      span {
        font-weight: bold;
        font-size: 15px;
        color: #353535;
      }
    }
  }
}