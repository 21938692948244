@import (reference) 'src/styles/variables.less';
@import (reference) 'src/styles/mixins.less';

.login-page {
  >div {
    min-width: 900px !important;
  }

  .bg {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
  }

  .content {
    background: #FFFFFF;
    box-shadow: 24px 20px 44px 6px rgba(41, 36, 49, 0.08);
    border-radius: 20px;
    margin: 14% auto 0;
    position: relative;
    padding: 32px 56px 36px 56px;
    width: fit-content;

    .logo {
      height: 40px;
    }

    .title {
      font-weight: bold;
      font-size: 30px;
      color: #333333;
      line-height: 36px;
      margin-top: 24px;
    }

    .login-btn {
      width: 358px;
      height: 56px;
      background: #6040ED;
      border-radius: 11px;
      margin-top: 40px;
      font-weight: bold;
      font-size: 16px;
      color: #FFFFFF;
      line-height: 19px;

      img {
        width: 26px;
        margin-right: 12px;
      }

      &:hover {
        background: #5839E1;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
      }

    }

    .desc {
      font-weight: 400;
      font-size: 16px;
      color: #333333;
      line-height: 22px;
      margin-top: 32px;

      a {
        font-size: 16px;
        color: #555E7C;
        line-height: 18px;
        text-decoration-line: underline;
        &:hover{
          color:#2A2C3F
        }
      }
    }

    .small-text {
      margin-top: 75px;
      font-size: 13px;
      color: #A3AABE;
      line-height: 15px;

      a {
        color: #555E7C;
        text-decoration-line: underline;
      }
    }

  }
}