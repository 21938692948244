@import '@/styles/mixins.less';
@import '@/styles/common-class.less';

@font-face {
  font-family: 'Hind';
  src: url('./assets/fonts/Hind-Regular.ttf') format('truetype');
  font-weight: normal;
  // font-style: normal;
}

@font-face {
  font-family: 'Hind';
  src: url('./assets/fonts/Hind-Medium.ttf') format('truetype');
  font-weight: 500;
  // font-style: normal;
}

@font-face {
  font-family: 'Hind';
  src: url('./assets/fonts/Hind-SemiBold.ttf') format('truetype');
  font-weight: 600;
  // font-style: normal;
}
html,
body {
  .global-font();
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  background: white;
  .normalize();
  margin: 0;
  padding: 0;
}

#root {
  .size(100%);
}

