html,
body {
  width: 100%;
  height: 100%;
}

body {
  .normalize();
}

.flex-col {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  >.row {
    &-1 {
      flex: 1;
    }

    &-2 {
      flex: 2;
    }

    &-3 {
      flex: 3;
    }
  }
}

.flex-row {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  >.col {
    &-1 {
      flex: 1;
    }

    &-2 {
      flex: 2;
    }

    &-3 {
      flex: 3;
    }

    &-4 {
      flex: 4;
    }
  }
}

.flex-row {
  &.align-center {
    justify-content: center;
  }

  &.align-left {
    justify-content: flex-start;
  }

  &.align-right {
    justify-content: flex-end;
  }

  &.align-side {
    justify-content: space-between;
  }

  &.align-round {
    justify-content: space-around;
  }

  &.align-even {
    justify-content: space-evenly;
  }

  &.justify-center {
    align-items: center;
  }

  &.justify-end {
    align-items: flex-end;
  }

  &.justify-start {
    align-items: flex-start;
  }

  &.justify-baseline {
    align-items: baseline;
  }
}

.flex-col {
  &.align-center {
    align-items: center;
  }

  &.align-left {
    align-items: flex-start;
  }

  &.align-right {
    align-items: flex-end;
  }

  &.align-side {
    align-items: space-between;
  }

  &.align-even {
    align-items: space-evenly;
  }

  &.justify-center {
    justify-content: center;
  }

  &.justify-start {
    justify-items: flex-start;
  }

  &.justify-side {
    justify-content: space-between;
  }

  &.justify-end {
    justify-content: flex-end;
  }
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center !important;
}

.full-w {
  width: 100%;
}

.full-h {
  height: 100%;
}

.full-wh {
  .full-w;
  .full-h;
}

.hide {
  display: none !important;
}

.transparent {
  opacity: 0 !important;
}

.invisible {
  visibility: hidden !important;
}

.disabled {
  cursor: not-allowed !important;
  pointer-events: none;
  background: #f3f3f3;
}

.bg-white {
  background: white;
}

.bold {
  font-weight: 500;
}

.bold-bold {
  font-weight: bold;
}

.text-bold {
  font-weight: bold;
}

.text-large {
  font-size: 16px;
}

.text-big {
  font-size: 15px;
}

.text-normal {
  font-size: 14px;
}

.text-13 {
  font-size: 13px;
}

.text-small {
  font-size: 12px;
}

.clickable {
  cursor: pointer;
  user-select: none;
}

.relative {
  position: relative;
}

.no-select {
  user-select: none;
}

.clear-spin-button {
  &::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    margin: 0;
  }
}

.font-size-12 {
  font-size: 12px;
}

.font-size-13 {
  font-size: 13px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-15 {
  font-size: 15px;
}

.font-size-16 {
  font-size: 16px;
}

.flex-wrap {
  flex-wrap: wrap;
}

.overflow-hidden {
  overflow: hidden;
}

.vertical-middle {
  vertical-align: middle;
}

.color-59 {
  color: #595959;
}

html {
  *[data-modal-active="true"] {
    touch-action: none;
    overscroll-behavior: none;
    -webkit-overflow-scrolling: auto;
    overflow: hidden !important;
  }
}

body {
  &[data-modal-active="true"] & {
    touch-action: none;
    overscroll-behavior: none;
    -webkit-overflow-scrolling: auto;
    overflow: hidden;
  }
}

/**
* 空状态修改新加
*/
.col-1 {
  flex: 1;
}

.padding-bottom-16 {
  padding-bottom: 16px;
}

.marginFn(@n, @i) when(@i =< @n) {
  .mt-@{i} {
    margin-top: @i*1px;
  }

  .mb-@{i} {
    margin-bottom: @i*1px;
  }

  .ml-@{i} {
    margin-left: @i*1px;
  }

  .mr-@{i} {
    margin-right: @i*1px;
  }

  .marginFn(@n, (@i + 1));
}

.marginFn(100, 0);