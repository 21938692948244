@import (reference) 'src/styles/variables.less';
@import (reference) 'src/styles/mixins.less';

:global {

  body,
  html {
    overflow: hidden;
  }

  #page-content {
    width: 100%;
  }
}

.loading-box {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.hy-layout {
  .size(100vw, 100vh);
  overflow: auto;
  .prettier-scroll-bar();
  // scroll-behavior: smooth;

  .wrapper {
    width: 100%;
    min-height: 100%;
    min-width: 1434px;

    &.with-alert {
      :global {
        .noam-page-header {
          top: 61px;
        }
      }
    }
  }
}

// 隐私协议收集面板
.privacy-collection-panel {
  width: 466px;
  background: #FFFFFF;
  box-shadow: 0px 6px 124px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  position: fixed;
  bottom: 40px;
  right: 40px;
  padding: 24px 32px;
  z-index: 3;

  .title {
    font-weight: bold;
    font-size: 20px;
    color: #22232F;
    line-height: 32px;
  }

  .text {
    font-weight: 400;
    font-size: 16px;
    color: #333333;
    line-height: 22px;
    margin-top: 8px;

    a{
      font-weight: bold;
    }
  }

  .accept-btn {
    .noam-btn-primary();
    min-width: 111px;
    padding: 0 24px;
    height: 36px;
    border-radius: 8px;
    font-weight: bold;
    font-size: 14px;
    margin-top: 16px;
  }
}