@import (reference) 'src/styles/variables.less';
@import (reference) 'src/styles/mixins.less';

.page-header {
  width: 100%;
  padding: 0 121px;
  height: 68px;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;


  @media (max-width:1000px){
    padding: 0 24px;
  }
  &.fixed-top {
    background: hsla(0, 0%, 100%, 0.8);
    -webkit-backdrop-filter: blur(6px);
    backdrop-filter: blur(6px);
  }

  .left {
    .logo {
      height: 36px;
      margin-right: 12px;
      .clickable();
    }

    .language-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 198px;
      height: 44px;
      border: 1px solid #e5e5e5;
      padding: 0 16px;
      background: rgba(255, 255, 255, 0.65);
      border-radius: 10px;
      border: 1px solid #e5e5e5;
      .clickable();

      .name {
        flex: 1 1;
        font-size: 14px;
        color: #2a2c3f;
        .text-ellipsis();
      }

      .arrow {
        font-size: 10px;
        color: #2a2c3f;
      }
    }
  }

  .right {
    .link {
      font-size: 14px;
      color: #2a2c3f;
      line-height: 17px;
      .clickable();
      margin-left: 40px;
      font-weight: 600;

      &:hover {
        color: #26283a;
        text-decoration: underline;
      }
    }

    .pdf-btn {
      min-width: 129px;
      height: 36px;
      background: rgba(255, 255, 255, 0.65);
      border-radius: 18px;
      border: 1px solid #d1c7ff;
      .flex-row();
      font-size: 14px;
      color: #6040ed;
      text-align: center;
      font-style: normal;
      .clickable();
      margin-left: 40px;
      font-weight: 600;
      padding: 0 8px;
      .icon {
        width: 18px;
        margin-right: 5px;
      }

      &:hover {
        background: #ffffff;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.06);
        border: 1px solid #c2b4ff;
        color: #6040ed;
      }
    }

    .login-btn {
      width: 101px;
      height: 36px;
      background: #2a2c3f;
      border-radius: 18px;
      margin-left: 16px;
      font-weight: bold;
      font-size: 14px;
      color: #ffffff;
      line-height: 36px;
      text-align: center;
      .clickable();
      font-weight: 600;

      &:hover {
        background: #282a3c;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.05);
      }
    }

    .user-info-box {
      position: relative;
      margin-left: 16px;
      cursor: pointer;

      &:hover {
        .menu-box-wrapper {
          opacity: 1;
        }
      }

      .avatar {
        width: 36px;
        height: 36px;
        border-radius: 18px;
        border: 1px solid #f9faff;
      }

      .icon-arrow {
        .size(6px, 6px);
        object-fit: contain;
        position: absolute;
        bottom: 0px;
        right: 0px;
      }

      .menu-box-wrapper {
        width: 127px;
        position: absolute;
        left: 0%;
        padding-top: 14px;
        top: 68%;
        opacity: 0;
        transition: opacity 0.3s ease;
      }

      .menu-box {
        background: white;
        border-radius: 4px;
        box-shadow: 0px 2px 10px 0px rgba(93, 93, 93, 0.15);
        border-radius: 10px;
        background-color: #fff;
        color: #333;
        padding: 4px 0;
        overflow: hidden;
        .flex-col();

        .menu-li {
          word-break: keep-all;
          white-space: nowrap;
          .clickable();
          width: 100%;
          padding: 0 26px;
          .flex-row();
          justify-content: flex-start;
          .h-lh(44px);

          &:hover {
            background: #f7f9fa;
          }

          .icon {
            .size(22px);
          }

          .text {
            font-size: 15px;
            font-weight: 400;
            color: #1c1e22;
            line-height: 18px;
            margin-left: 4px;
          }
        }

        .line {
          width: 76px;
          height: 1px;
          background-color: #f2f2f2;
        }
      }
    }
  }
}
