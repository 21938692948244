@import (reference) 'src/styles/variables.less';
@import (reference) 'src/styles/mixins.less';

.new-user-countdown-alert {
  .size(100%, 61px);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  overflow: hidden;

  >.bg {
    width: 100%;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    min-height: 100%;
  }


  .close-icon {
    color: white;
    position: absolute;
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
    .clickable();
  }

  >.box {
    .size(100%, 100%);
    position: relative;
    .flex-row();


    .countdown-icon {
      width: 30px;
      margin-right: 20px;
    }

    .countdown-box {
      .flex-row();
      margin-right: 58px;

      .countdown-item {
        width: 32px;
        height: 32px;
        background: linear-gradient(141deg, #6C5DFF 0%, #FEC9D7 100%);
        border-radius: 9px;
        border: 1px solid #FFFFFF;
        .flex-row();
        font-weight: 600;
        font-size: 20px;
        color: #FFFFFF;
      }

      .colon {
        font-weight: 600;
        font-size: 17px;
        color: rgba(255, 255, 255, 0.65);
        margin: 0 8px;
      }
    }

    >.center {
      .flex-row();
      margin-right: 30px;

      .star-icon {
        width: 21px;
        margin-right: 7px;
      }

      .label {
        font-weight: 600;
        font-size: 20px;
        color: #FFFFFF;
        margin-right: 6px;
      }

      .strong {
        font-weight: 600;
        font-size: 22px;
        color: #FFE138;
        line-height: 32px;
        padding: 0 6px;
        display: inline-block;
        position: relative;

        span {
          position: relative;
          z-index: 2;
        }

        &::after {
          content: '';
          display: block;
          width: 100%;
          height: 8px;
          background: #E39CFA;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          bottom: 5px;
        }
      }
    }

    .join-btn {
      height: 36px;
      padding: 0 20px;
      background: #FFFFFF;
      border-radius: 18px;
      font-weight: bold;
      font-size: 14px;
      color: #6040ED;
      .flex-row();

      &:hover {
        box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.16);
        color: #6040ED;
      }

      .arrow-icon {
        width: 16px;
        margin-left: 8px;
      }
    }
  }

}