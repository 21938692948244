@import (reference) 'src/styles/variables.less';
@import (reference) 'src/styles/mixins.less';

.upgrade-modal {
  :global {
    .ant-modal-content {
      border-radius: 20px;
      padding: 24px 40px 38px;
    }
  }

  &.free{
    :global {
      .ant-modal-content {
        padding-bottom: 24px;
      }
    }
  }

  .upgrade-box-content {
    justify-content: flex-start;
    min-height: 413px;
    >.title {
      font-weight: bold;
      font-size: 20px;
      color: #22232F;
      line-height: 24px;
    }

    .bg{
      width: 292px;
      margin-top: 40px;
      margin-bottom: 24px;
    }
    >.text{
      font-size: 16px;
      color: #22232F;
      line-height: 24px;
      text-align: center;
    }

    >.desc {
      margin-top: 13px;
      margin-bottom: 32px;
      width: 100%;
      background: #FEF8E9;
      border-radius: 4px;
      padding: 9px 16px;


      .icon {
        width: 19px;
        margin-right: 4px;
      }

      span {
        font-size: 14px;
        color: #22232F;
      }
    }


    >.buy-btn {
      background: #6040ED;
      border-color: #6040ED;
      color: #FFFFFF;
      font-size: 16px;
      font-weight: 600;
      width: 301px;
      height: 48px;
      border-radius: 10px;
      font-weight: 600;
      font-size: 16px;
      color: #FFFFFF;
      margin-top: 40px;

      .icon {
        width: 19px;
        margin-right: 7px;
      }

      &:hover {
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
        background: #6040ED !important;
        border: 1px solid #6040ED !important;
        color: #FFFFFF !important;
      }
    }

    .more {
      .flex-row();
      margin-top: 24px;
      .clickable();

      &:hover{
        span{
          color:#583ADA;
        }
      }

      span {
        font-weight: bold;
        font-size: 16px;
        color: #6040ED;
      }

      .ame-icon{
        font-size: 20px;
        margin-left: 6px;
      }
    }
  }
}