@import (reference) 'src/styles/variables.less';
@import (reference) 'src/styles/mixins.less';

.page-footer {
  width: 100%;
  background: #FFFFFF;
  box-shadow: 0px -1px 0px 0px #F2F2F2;
  padding: 54px 0 72px;
  .flex-row();

  >.footer-content {
    .flex-row();
    align-items: flex-start;
    justify-content: space-between;
    min-width: 1200px;
    .left {
      padding-right: 16px;
      .logo {
        height: 36px;
      }

      .text {
        font-weight: 400;
        font-size: 15px;
        color: #000000;
        line-height: 21px;
        margin-top: 22px;
      }

      .icp {
        font-weight: 400;
        font-size: 14px;
        color: #000000;
        line-height: 20px;
        margin-top: 18px;
      }
    }

    .right {
      .box {
        display: flex;
        flex-direction: column;
        .title {
          font-weight: 600;
          font-size: 18px;
          color: #22232F;
          margin-bottom: 28px;
        }

        .link {
          font-weight: 400;
          font-size: 15px;
          color: #22232F;
          line-height: 21px;
          &:hover{
            text-decoration: underline;
            color: #26283A;
          }
          &:not(:last-child){
            margin-bottom: 16px;
          }
        }
      }
    }
  }
}