@import (reference) 'src/styles/variables.less';
@import (reference) 'src/styles/mixins.less';

.contact-text {
  font-size: 16px;
  color: #9095A2;
  .email{
    color:rgba(96, 64, 237, 1);
    text-decoration: underline;
  }
}