@import (reference) 'src/styles/variables.less';
@import (reference) 'src/styles/mixins.less';

.plan-item-v1 {
  width: 100%;
  min-height: 310px;
  border-radius: 19px;
  position: relative;
  margin-top: 10px;

  .item-box {
    border-radius: 19px;
    overflow: hidden;
    border: 2px solid #FFFFFF;
    box-shadow: 0px 6px 40px 2px rgba(34, 34, 34, 0.12);
    padding: 0 32px 26px;

    .box {
      width: 274px;
      background: linear-gradient(317deg, #FEFCF5 0%, #FEF6EF 30%, #FFFAF1 46%, #FFFCF8 62%, #FFF6EF 100%);
      border-radius: 16px;
      border: 1px solid #FFE2AB;
      position: relative;
      padding: 26px 20px;

      .tag {
        height: 18px;
        border-radius: 12px 0px 12px 0px;
        border: 1px solid #FFE3AE;
        padding: 0 12px;
        position: absolute;
        left: 0;
        top: 0;
        font-weight: bold;
        font-size: 12px;
        color: #CA8000;
      }
    }
  }

  &.pro {
    .item-box {
      background: linear-gradient(316deg, #F6F5FE 0%, #F0EFFE 30%, #F2EFFE 46%, #ECE8FE 62%, #E9E5FE 100%);
    }

    .version-info {
      background: linear-gradient(119deg, #FFFFFF 0%, #EFEBFE 46%, #FFFFFF 100%);

      span {
        font-size: 19px;
        color: #906BDA;
        line-height: 26px;
        text-align: left;
        font-style: normal;
        background: linear-gradient(58.54034973461311deg, #8F61ED 0%, #8453F2 45%, #692FFF 100%, #6040ED 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      .tag {
        background: #866BEE;
      }
    }

  }

  &.pro-v {
    .item-box {
      background: linear-gradient(145deg, #FFEED4 0%, #FFF6E3 28%, #FDEED7 100%);
    }

    .version-info {
      span {
        font-size: 19px;
        color: #986BDA;
        line-height: 26px;
        text-align: left;
        font-style: normal;
        background: linear-gradient(50.95069484660149deg, #DBA10A 0%, #DEA541 45%, #CA820A 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

    }

    .tag {
      background: #FFF6C7;
    }
  }

  .version-info {
    min-width: 124px;
    width: fit-content;
    height: 36px;
    border-radius: 0px 0px 16px 0px;
    background: linear-gradient(316deg, #FCFAF6 0%, #FEF9EF 31%, #FFFAF2 46%, #FFFBF0 62%, #FFFAF5 100%);
    border: 1px solid #FFFFFF;
    .flex-row();
    font-weight: 600;
    padding: 0 20px;
    position: relative;
    left: -32px;
    margin-bottom: 13px;

    .icon {
      width: 26px;
      margin-right: 8px;
    }

    .tag {
      display: inline-block;
      width: 72px;
      height: 20px;
      border-radius: 6px;
      text-align: center;
      font-size: 14px;
      color: #FFFFFF;
      line-height: 20px;
      margin-left: 6px;
    }
  }

  .hot-img {
    width: 46px;
    position: absolute;
    right: 2px;
    top: 1px;
  }


  .box {

    &:not(.b-list):not(:last-child) {
      margin-bottom: 12px;
    }

    &.b-list{
      padding: 30px 20px;
      .b-item:not(:last-child) {
        margin-bottom: 24px;
      }
    }

    &.row2{
      padding: 17px 20px;
      .b-item:not(:last-child) {
        margin-bottom: 12px;
      }
    }

    &.row1{
      padding: 12px 20px;
    }
    .b-item {
      display: flex;
      align-items: center;

      .ame-icon {
        font-size: 20px;
        margin-right: 8px;

        &.pro-v {
          color: #DDA33D;
        }

        &.pro {
          color: #6840EA;
        }
      }

      .img {
        width: 22px;
        margin-right: 8px;
      }

      span {
        font-weight: 600;
        font-size: 14px;
        color: #333333;
      }
    }
  }

  .original-box {
    position: absolute;
    .size(88px, 87px);
    background: url('~@/assets/trialModal/price-bg.png');
    background-size: 100% 100%;
    font-weight: 600;
    font-size: 26px;
    color: #22232F;
    .flex-col();
    bottom: -6px;
    right: -40px;
    text-decoration: line-through;
  }
}