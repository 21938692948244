@import (reference) 'src/styles/variables.less';
@import (reference) 'src/styles/mixins.less';

.language-wrap {
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: normal;
  width: 280px;
  height: 326px;

  &.single-line {
    height: 296px;
  }

  .search-box {
    position: relative;
    .input {
      width: 100%;
      height: 36px;
      background: #f7f9fa;
      border-radius: 6px;
      border: 1px solid #f2f2f2;
      padding: 8px 12px;
      outline: none;

      font-weight: 500;
      font-size: 14px;
      color: #1c1e22;
      text-align: left;
      font-style: normal;

      &::placeholder {
        font-weight: 400;
        font-size: 14px;
        color: #999999;
        text-align: left;
        font-style: normal;
      }
    }

    .icon-box {
      .size(16px, 16px);

      position: absolute;
      right: 12px;
      top: 9px;
      .flex-row();
      img {
        width: 100%;
      }

      .close-icon {
        .clickable();
      }
    }
  }

  .language-list {
    flex: 1;
    overflow-y: auto;
    .prettier-scroll-bar();
    width: 100%;
    margin-top: 10px;
    position: relative;

    .language-item {
      .clickable();

      &.active {
        .main {
          color: #6040ed;
        }
      }

      &:hover {
        background: #f7f9fa;
        border-radius: 8px;
      }

      .main {
        font-weight: 500;
        font-size: 14px;
        color: #1c1e22;
        text-align: left;
        line-height: 20px;
        font-style: normal;
      }

      .secondary {
        font-weight: 400;
        font-size: 12px;
        color: #666666;
        text-align: left;
        line-height: 20px;
        font-style: normal;
      }

      &.single-line {
        padding: 12px;
      }

      &.double-line {
        padding: 6px 12px;
      }
    }

    .empty-box {
      .flex-col();
      font-size: 12px;
      color: #1c1e22;
      text-align: center;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      .empty-icon {
        .size(162px,106px);
      }
    }
  }
}
