@import (reference) '@/styles/variables.less';
@import (reference) '@/styles/mixins.less';

.ant-message,
.ant-message-fixed {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.88);
  font-size: 14px;
  line-height: 1.5714285714285714;
  list-style: none;
  .global-font();
  position: absolute;
  top: 8px;
  width: 100%;
  pointer-events: none;
  z-index: @z-index-toast;
}

.ant-message-fixed {
  position: fixed;
}

.ant-message .ant-message-move-up,
.ant-message-fixed .ant-message-fixed-move-up {
  animation-fill-mode: forwards;
}

.ant-message .ant-message-move-up-appear,
.ant-message .ant-message-move-up-enter,
.ant-message-fixed .ant-message-fixed-move-up-appear,
.ant-message-fixed .ant-message-fixed-move-up-enter {
  animation-name: css-1me4733-MessageMoveIn;
  animation-duration: 0.3s;
  animation-play-state: paused;
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-message .ant-message-move-up-appear.ant-message-move-up-appear-active,
.ant-message .ant-message-move-up-enter.ant-message-move-up-enter-active,
.ant-message-fixed .ant-message-fixed-move-up-appear.ant-message-fixed-move-up-appear-active,
.ant-message-fixed .ant-message-fixed-move-up-enter.ant-message-fixed-move-up-enter-active {
  animation-play-state: running;
}

.ant-message .ant-message-move-up-leave,
.ant-message-fixed .ant-message-fixed-move-up-leave {
  animation-name: css-1me4733-MessageMoveOut;
  animation-duration: 0.3s;
  animation-play-state: paused;
  animation-timing-function: cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.ant-message .ant-message-move-up-leave.ant-message-move-up-leave-active,
.ant-message-fixed .ant-message-fixed-move-up-leave.ant-message-fixed-move-up-leave-active {
  animation-play-state: running;
}

.ant-message-rtl,
.ant-message-fixed-rtl {
  direction: rtl;
}

.ant-message-rtl span,
.ant-message-fixed-rtl span {
  direction: rtl;
}

.ant-message-notice,
.ant-message-fixed-notice {
  padding: 8px;
  text-align: center;
}

.ant-message-notice .ant-message-custom-content > .anticon,
.ant-message-notice .ant-message-fixed-custom-content > .anticon,
.ant-message-fixed-notice .ant-message-fixed-custom-content > .anticon,
.ant-message-fixed-notice .ant-message-custom-content > .anticon {
  vertical-align: middle !important;
  margin-inline-end: 8px;
  font-size: 16px;
}

.ant-message-notice .ant-message-notice-content,
.ant-message-fixed-notice .ant-message-fixed-notice-content {
  display: inline-block;
  padding: 9px 12px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  pointer-events: all;
}

// .ant-message-notice-warning .ant-message-notice-content,
// .ant-message-fixed-notice-warning .ant-message-fixed-notice-content {
//   background: #faad14 !important;
//   color: #fff !important;
// }

.ant-message-notice .ant-message-success > .anticon,
.ant-message-notice .ant-message-fixed-success > .anticon,
.ant-message-fixed-notice .ant-message-fixed-success > .anticon,
.ant-message-fixed-notice .ant-message-success > .anticon {
  color: #52c41a;
}

.ant-message-notice .ant-message-error > .anticon,
.ant-message-notice .ant-message-fixed-error > .anticon,
.ant-message-fixed-notice .ant-message-fixed-error > .anticon,
.ant-message-fixed-notice .ant-message-error > .anticon {
  color: #ff4d4f;
}

.ant-message-notice .ant-message-warning > .anticon,
.ant-message-notice .ant-message-fixed-warning > .anticon,
.ant-message-fixed-notice .ant-message-fixed-warning > .anticon,
.ant-message-fixed-notice .ant-message-warning > .anticon {
  color: #fff;
}

.ant-message-notice .ant-message-info > .anticon,
.ant-message-notice .ant-message-loading > .anticon,
.ant-message-fixed-notice .ant-message-fixed-info > .anticon,
.ant-message-fixed-notice .ant-message-fixed-loading > .anticon {
  color: #1677ff;
}

.ant-message-notice-pure-panel,
.ant-message-fixed-notice-pure-panel {
  padding: 0;
  text-align: start;
}
