@import (reference) 'src/styles/variables.less';
@import (reference) 'src/styles/mixins.less';

.trial-modal {
  :global {
    .ant-modal-content {
      border-radius: 20px;
      padding: 0;
      box-shadow: none;
      background: none;
    }

    .ant-modal-close{
      transition: color 0.3s,background-color 0.3s;
    }
  }

  .guide {
    width: 392px;
    transition: opacity 0.1s ease-in-out;

    &.hide {
      opacity: 0;
    }
  }

  .gift {
    width: 608px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .trial-plan-box {
    background: #FFFFFF;
    border-radius: 20px;
    padding: 22px 72px 36px;
    position: relative;

    // transition: transform 0.3s ease-in-out;
    &:not(.show) {
      transform: scale(0);

    }

    &.show {
      animation: scale 0.3s;
    }

    >.bg {
      width: 100%;
      position: absolute;
      left: 0;
      top: 0;
    }

    >.box {
      position: relative;
      .flex-col();

      >.title {
        font-weight: 600;
        font-size: 20px;
        color: #22232F;
        .flex-row();

        .icon {
          width: 20px;
          margin-right: 9px;
        }
      }

      >.price-row {
        font-weight: 600;
        font-size: 44px;
        color: #22232F;
        line-height: 70px;
        margin-top: 8px;
        position: relative;
        padding: 0 3px;

        .trial-text {
          .size(93px, 24px);
          background: url('~@/assets/trialModal/text-bg-1.png');
          background-size: 100% 100%;
          text-align: center;
          font-weight: 600;
          padding-left: 6px;
          font-size: 15px;
          color: #353535;
          line-height: 24px;
          position: absolute;
          right: 0;
          top: 50%;
          transform: translate(100%, -50%);
        }
      }

      >.buy-btn {
        background: #6040ED;
        border-color: #6040ED;
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 600;
        width: 345px;
        height: 48px;
        border-radius: 10px;
        font-weight: bold;
        font-size: 16px;
        color: #FFFFFF;
        margin-top: 40px;

        .icon {
          width: 19px;
          margin-right: 7px;
        }

        &:hover {
          box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
          background: #6040ED !important;
          border: 1px solid #6040ED !important;
          color: #FFFFFF !important;
        }
      }

      >.text {
        font-size: 14px;
        color: #9095A2;
        .flex-row();
        margin-top: 30px;
      }
    }
  }

}


@keyframes scale {
  0% {
    transform: scale(0.2);
    opacity: 0.2;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}