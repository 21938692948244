@import (reference) '../../styles/mixins.less';

.common-dialog-layout {}


.common-modal {
  :global {
    .ant-modal-content {
      border-radius: 20px;
      padding: 24px 32px;
    }

    .ant-modal-footer{
      margin-top: 24px;
    }

    .ant-btn-default {
      border: 1px solid #DDDDDD;
      background: #FFFFFF;
      color: #333333;
      height: 44px;
      font-size: 16px;
      font-weight: 600;
      min-width: 120px;

      &:hover {

        border: 1px solid #CACACA !important;
        background: #FFFFFF !important;
        color: #2E2E2E !important;
      }
    }

    .ant-btn-primary {
      background: #6040ED;
      border: 1px solid #6040ED;
      color: #FFFFFF;
      height: 44px;
      font-size: 16px;
      font-weight: 600;
      min-width: 120px;

      margin-inline-start: 16px !important;

      &:hover {

        background: #5B3DE2 !important;
        border: 1px solid #5B3DE2 !important;
        color: #FFFFFF !important;
      }
    }
  }
}