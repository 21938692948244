@import (reference) 'src/styles/variables.less';
@import (reference) 'src/styles/mixins.less';

.buy-modal {
  :global {
    .ant-modal-content {
      padding: 0;
      width: 608px;
      box-shadow: none;
      background: none;
    }
  }
  .guide {
    width: 392px;
    transition: opacity 0.1s ease-in-out;

    &.hide {
      opacity: 0;
    }
  }

  .gift {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .upgrade-box-content {
    width: 608px;
    background: #FFFFFF;
    border-radius: 20px;
    padding: 24px 56px 40px;
    &:not(.show) {
      transform: scale(0);

    }

    &.show {
      animation: scale 0.3s;
    }
    >.title {
      font-weight: bold;
      font-size: 20px;
      color: #22232F;
      line-height: 24px;
    }

    >.desc {
      margin-top: 13px;
      // background: linear-gradient(90deg, #FFFFFF 0%, #EFEBFE 46%, #FFFFFF 100%);
      width: 208px;
      height: 39px;
      .flex-row();
      background: url('~@/assets/trialModal/pro-trial-text-bg.png');
      background-size: 100% 100%;

      .icon {
        width: 21px;
        margin-right: 6px;
      }

      span {
        font-weight: 600;
        font-size: 18px;
        color: #22232F;
      }
    }

    >.plan-type {
      margin-top: 5px;
      width: 100%;

      .time-type-select {
        width: 168px;
        height: 40px;

        :global {
          .ant-select-selector {
            background: #FFFFFF;
            border-radius: 10px;
            border: 1px solid #D8D8D8 !important;
          }
        }
      }

      .label {
        font-size: 16px;
        color: #999999;
      }
    }

    .plan-box {
      margin-top: 12px;
    }

    >.price-box {
      margin-top: 16px;

      .left {
        .desc {
          font-weight: bold;
          font-size: 16px;
          color: #22232F;
          line-height: 19px;
        }

        .time {
          font-size: 16px;
          color: #22232F;
          line-height: 19px;
          margin-top: 13px;
        }
      }

      .right {
        text-align: right;

        .price {
          font-weight: bold;
          font-size: 32px;
          color: #22232F;
          line-height: 38px;
        }

        .pre-price {
          font-weight: bold;
          font-size: 16px;
          color: #22232F;
          line-height: 19px;
          margin-top: 4px;
        }
      }
    }

    >.buy-btn {
      background: #6040ED;
      border-color: #6040ED;
      color: #FFFFFF;
      font-size: 16px;
      font-weight: 600;
      width: 301px;
      height: 48px;
      border-radius: 10px;
      font-weight: bold;
      font-size: 16px;
      color: #FFFFFF;
      margin-top: 24px;

      .icon {
        width: 19px;
        margin-right: 7px;
      }

      &:hover {
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.1);
        background: #6040ED !important;
        border: 1px solid #6040ED !important;
        color: #FFFFFF !important;
      }
    }
  }
}

@keyframes scale {
  0% {
    transform: scale(0.2);
    opacity: 0.2;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}