@import (reference) 'src/styles/variables.less';
@import (reference) 'src/styles/mixins.less';

.login-modal {
  :global {
    .ant-modal-content {
      border-radius: 20px;
      padding: 0;
      box-shadow: none;
      background: linear-gradient(180deg, #D9D0FF 0%, #FFFFFF 60%, #FFFFFF 100%);
    }

  }

  .content {
    padding: 32px 20px 24px 20px;

    .logo {
      width: 132px;
    }

    .desc {
      margin-top: 18px;
      width: 100%;
      height: 71px;
      position: relative;

      .bg {
        height: 71px;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
      }

      span {
        position: relative;
        text-align: center;
        font-weight: 600;
        font-size: 24px;
        color: rgba(96, 64, 237, 1);
      }
    }

    .login-btn {
      width: 358px;
      height: 56px;
      background: #6040ED;
      border-radius: 11px;
      margin-top: 36px;
      font-weight: bold;
      font-size: 16px;
      color: #FFFFFF;
      line-height: 19px;

      img {
        width: 26px;
        margin-right: 12px;
      }

      &:hover {
        background: #5839E1;
        box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
      }

    }

    .small-text {
      margin-top: 50px;
      font-size: 13px;
      color: #A3AABE;
      line-height: 15px;

      a {
        color: #555E7C;
        text-decoration-line: underline;

        &:hover{
          color:#2A2C3F
        }
      }
    }
  }
}